import Slider from '..'
import Link from 'next/link'
import Image from 'next/image'
import s from './home_slider.module.scss'

export default function HomeSlider({ banners, isMobile, hidden }) {
   if (hidden) return null

   const bannerHref = {
      bundle: v => `/bundle/${v}`,
      category: v => `/products/category/${v}`,
      good: v => `/products/${v}`,
      pack: v => `/bundle/${v}?pack=true`,
      news: v => `/news/${v}`,
      local_transition: v => v,
      link: v => v
   }

   const adaptedBanners = banners.map(banner => ({
      id: banner.id,
      src: banner.sources[isMobile ? 'normal' : 'wide'],
      href: bannerHref[banner.type] && bannerHref[banner.type](banner.hrefValue)
   }))

   const sizes = {
      width: isMobile ? 1100 : 1920,
      height: isMobile ? 602 : 510
   }

   return (
      <Slider
         isMobile={ isMobile }
         slides={ adaptedBanners }
         slideBody={ (src, priority = false, href) => (
            <div className={ isMobile ? s.mobile_slide_inner : s.desktop_slide_inner }>
               {
                  href ? (
                     <Link href={ href }>
                        <a>
                           <Image
                              src={ src }
                              quality={ 100 }
                              width={ sizes.width }
                              height={ sizes.height }
                              //priority={ priority }
                              alt=""
                              loading="lazy"
                           />
                        </a>
                     </Link>
                  ) : (
                     <Image
                        src={ src }
                        quality={ 100 }
                        width={ sizes.width } // default 1100. For wide 1920
                        height={ sizes.height } // default 602. For wide 510
                        //priority={ priority }
                        alt=""
                        loading="lazy"
                     />
                  )
               }
            </div>
         ) }
         params={ {
            navigation: true,
            pagination: { clickable: true },
            autoplay: { delay: 5000, disableOnInteraction: false },
            loop: true
         } }
      />
   )
}
