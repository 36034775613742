import { useState } from 'react'
import Image from 'next/image'
import SwiperCore, { Navigation, Pagination, Autoplay, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import s from './slider.module.scss'

SwiperCore.use([
   Navigation,
   Pagination,
   Autoplay,
   Thumbs
])

const Slider = ({
   isMobile,
   slides,
   slideBody,
   params,
   thumbs,
   arrowsSrc = {
      left: '/images/slider/circle-arrow-prev.svg',
      right: '/images/slider/circle-arrow-next.svg',
   }
}) => {

   const [thumbsSwiper, setThumbsSwiper] = useState(null)

   const slidesItems = slides.map(({ id, src, background, href }, idx) => (
      <SwiperSlide key={ `${ id }${ href }` }>
         <div className={ s.slide } style={ { background } }>
            { slideBody(src, idx === 0, href) }
         </div>
      </SwiperSlide>
   ))

   const thumbsList = thumbs ? <ThumbsContent slidesItems={ slidesItems } setThumbsSwiper={ setThumbsSwiper }/> : null

   const arrows = params?.navigation ? <CustomArrows left={ arrowsSrc.left } right={ arrowsSrc.right }/> : null
   const navigation = params?.navigation ? {
      nextEl: '.' + s.button_next,
      prevEl: '.' + s.button_prev,
   } : false

   const pagination = slides?.length > 1 && isMobile ? {
      bulletActiveClass: s.bullet_active,
      bulletClass: s.bullet,
      clickable: true,
   } : false

   return (
      <div className={ `${s.slider} ${!isMobile && s.wide}` }>
         { arrows }
         <Swiper
            { ...params }
            navigation={ navigation }
            pagination={ pagination }
            thumbs={ { swiper: thumbsSwiper } }
         >
            { slidesItems }
         </Swiper>

         { thumbsList }
      </div>
   )
}

const CustomArrows = ({ left, right }) => (
   <div className={ s.arrows_wrapper }>
      <div className={ s.button_prev }>
         <Image
            src={ left }
            width={ 28 }
            height={ 28 }
            onError={ e => console.log(e) }
            alt=""
         />
      </div>
      <div className={ s.button_next }>
         <Image
            src={ right }
            width={ 28 }
            height={ 28 }
            alt=""
         />
      </div>
   </div>
)

const ThumbsContent = ({ slidesItems, setThumbsSwiper, slidesPerView }) => (
   <div className={ s.thumbs }>
      <Swiper onSwiper={ setThumbsSwiper } watchSlidesVisibility watchSlidesProgress slidesPerView={ 5 }
         spaceBetween={ 15 }>
         { slidesItems }
      </Swiper>
   </div>
)

export default Slider
